import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import logo from '../static/hs_logo.png'; // Update the path if necessary


function Header() {

  const [username, setUsername] = useState('');

  useEffect(() => {
    const handleStorageChange = () => {
      setUsername(sessionStorage.getItem('username') || '');
    };

    // Update the username when the component mounts
    handleStorageChange();

    // Update the username whenever the session storage changes
    window.addEventListener('storage', handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <img src={logo} alt="Logo" style={{ height: '1in', marginRight: '10px', transform: 'scale(0.8)' }} />
        <div style={{ flexGrow: 1 }} />
        <Typography variant="h6">
          {username}
        </Typography>
        <div style={{ width: '1in' }} /> {/* This empty div takes up the same space as the logo */}
      </Toolbar>
    </AppBar>
  );
}

export default Header;