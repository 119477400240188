import React, { useEffect, useRef } from 'react';
import { Button, Typography, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import tina1 from '../static/tina-1.png';

const Results = () => {

  const jobsMap = JSON.parse(sessionStorage.getItem('jobsMap')) || [];
  const index = parseInt(sessionStorage.getItem('currentIndex'), 10);
  const history = useHistory();
  const [question, setQuestion] = React.useState('');
  const [analysis, setAnalysis] = React.useState(''); 
  const [loading, setLoading] = React.useState(false);
  const [interacting, setInteracting] = React.useState(false);
  const [showClearButton, setShowClearButton] = React.useState(false);
  const analysisRef = useRef(null);
  const questionRef = useRef(null);
  const baseurl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
  const process_candidates_url = baseurl + 'processcandidates';
  const get_run_results_url = baseurl + 'getrunresults';
  const interact_url = baseurl + 'interact';
  const authenticate_url = baseurl + 'authenticate';
  const topn_candidates = 5;
  const EXTENDED_TIMEOUT = 360000;  // 6 minutes

  const fetchWithTimeout = (url, options) => {
    return Promise.race([
      fetch(url, options),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Request timed out')), EXTENDED_TIMEOUT)
      )
    ]);
  };

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const renew_token = async () => {
    const email = sessionStorage.getItem('email');
    const password = sessionStorage.getItem('password');
    let response;
    let data;
    try {
      response = await fetch(authenticate_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email, password: password })
      });
      data = await response.json();
    } catch (error){
      setErrorMessage1('A network error occured, attempting to refresh the session token.  Please login again, or contact Heminger Solutions for assistance.');
    }
    if (response.ok) {
      sessionStorage.setItem('token', data.token);
      console.log('Token renewed');
    }
  };

  const handleSubmit = async (event) => {   // Interact with Tina here
    event.preventDefault();
    console.log('Submitting the question: ', question);
    let response;
    let data;
    setLoading(true);
    setInteracting(true);
    try {
      response = await fetch(interact_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: sessionStorage.getItem('token'), userid: sessionStorage.getItem('userid'), roleid: jobsMap[index].roleid, prompt: question})
      });
      data = await response.json();
    }  catch (error) { 
      setLoading(false);
      setInteracting(false);
      setAnalysis('Tina is reporting an error in processing your question, she suggests contacting Heminger Solutions for assistance.  Error: ' + error.message);
      console.log('Results.js, Error in processing user question: ', error.message);
      return; 
    }
    if (response.ok) {
      let status = 'in_progress';
      while (['queued', 'in_progress'].includes(status)) {
        await sleep(3000);  // wait for 2 seconds before checking the results
        try {
          response = await fetchWithTimeout(get_run_results_url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: sessionStorage.getItem('token'), roleid: jobsMap[index].roleid})
          });
          data = await response.json();
        } catch (error) { 
          setLoading(false);
          setInteracting(false);
          setAnalysis('Tina is reporting an error while waiting for processing of your question.  She suggests contacting Heminger Solutions for assistance.  Error: ' + error.message);   
          console.log('Results.js, Error in get_run_results: ', error.message); 
          return;
        }
        status = data.runstatus;
        if (response.ok) {
          if (status === 'completed') {
            setLoading(false);
            setInteracting(false); 
            const now = new Date();
            const nowstr = now.toLocaleString();
            const user = sessionStorage.getItem('username');
            setAnalysis(prevAnalysis => prevAnalysis + '\n\n ====== INTERACTION WITH TINA @ ' + nowstr + ' ' +  '======\n\n' + user + ' :\n' + question + '\n\n' + 'Tina:\n' + data.response);
           if (analysisRef.current) {
              analysisRef.current.scrollTop = analysisRef.current.scrollHeight;
           }
           setQuestion('');  // clear question after processing
           if (questionRef.current) {
            questionRef.current.focus();
           }
            return;
          }
        }
        else {
          if (data.returncode === 401) {
            await renew_token();            
            window.location.reload();
            return;
        }
          setLoading(false);
          setInteracting(false);
          setAnalysis('Tina is reporting an error in processing your question. Error: ' + data.errormsg);
          console.log('Results.js, Error in get_run_results, in get_run_results, error: ' + data.errormsg);
          return;
        }
      }
   }
   if (data.returncode === 401) {
    await renew_token(); 
    window.location.reload();
    return;
}
  };

  const handleBack = () => {
    history.push('/HandleJobs');
  };
  
  const handleClear = () => {
    setQuestion('');
    console.log('Clearing the question');
  };

  const get_top_candidates = async () => {
    /* First, get the dirty flag + current status of the current role */
    let dirty = jobsMap[index].resumesDirty;
    let data = {};
    let response = {};
    let status = 0;
    let estimated_secs = 0;
    try {
      response = await fetchWithTimeout(get_run_results_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: sessionStorage.getItem('token'), roleid: jobsMap[index].roleid})
      });
      data = await response.json();
    } catch (error) { 
      setAnalysis('Tina is reporting an error while checking process results of the current role Error: ' + error.message + '.  She suggests contacting Heminger Solutions for assistance.');   
      console.log('Results.js, Error in get_run_results: ', error.message); 
      setLoading(false);
      return;
    }
    let job_status = data.runstatus;
    /* if the resumes have not changed, and the current job is ready, then just display the results */
    if (job_status === 'completed' && !dirty) {
        const now = new Date();
        const nowstr = now.toLocaleString();
        setAnalysis('At ' + nowstr + ' Tina says: \n\n' + data.response);
        if (questionRef.current) {
          questionRef.current.focus();
         }
         jobsMap[index].resumesDirty = false;
         jobsMap[index].status = job_status;
         jobsMap[index].numcandidates = data.num_candidates;
         sessionStorage.setItem('jobsMap', JSON.stringify(jobsMap));
         setLoading(false);
        return;
      }
      else {
      /* if the previous job is complete or not started, and the resumes have changed, then run the job */
      setLoading(true);
      if ((job_status === 'completed'|| job_status === 'None') && dirty) {
        jobsMap[index].status = 'in_progress';
        sessionStorage.setItem('jobsMap', JSON.stringify(jobsMap));
        try {
          response = await fetchWithTimeout(process_candidates_url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: sessionStorage.getItem('token'), roleid: jobsMap[index].roleid, resumesdirty: dirty})
          });
          data = await response.json();
          job_status = 'in_progress';
          jobsMap[index].resumesDirty = false;
          jobsMap[index].estimated_mins = data.estimated_processing_secs/60.0;
          jobsMap[index].status = job_status;
          sessionStorage.setItem('jobsMap', JSON.stringify(jobsMap));
        } catch (error) { 
          setAnalysis('Tina is reporting an error kicking of the processing of the resumes.  She suggests contacting Heminger Solutions for assistance.  Error: ' + error.message);   
          console.log('Results.js, Error in processing candidates: ', error.message); 
          setLoading(false);
          return;
        }
      }
      if (job_status === 'failed') {
        setAnalysis('Tina is reporting an error in processing candidates as follows: ' + data.errormsg);   
        console.log('Results.js, Error in get_run_results: ', data.errormsg); 
        setLoading(false);
        return;
      }
      if (job_status === 'in_progress' || job_status === 'queued') {
        while (['queued', 'in_progress'].includes(job_status)) {
          await sleep(2000);  // wait for 2 seconds before checking the results
          try {
            response = await fetchWithTimeout(get_run_results_url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ token: sessionStorage.getItem('token'), roleid: jobsMap[index].roleid})
            });
          } catch (error) { 
            setAnalysis('Tina is reporting an error while waiting for processing of candidates to complete.  She suggests contacting Heminger Solutions for assistance.  Error: ' + error.message);   
            console.log('Results.js, Error in get_run_results: ', error.message); 
            setLoading(false);
            return;
          }
          data = await response.json();
          job_status = data.runstatus;
          jobsMap[index].numcandidates = data.num_candidates;
          if (job_status === 'completed') {
            setLoading(false);
            const now = new Date();
            const nowstr = now.toLocaleString();
            setAnalysis('At ' + nowstr + ' Tina says: \n\n' + data.response);
            if (questionRef.current) {
              questionRef.current.focus();
             }
             jobsMap[index].resumesDirty = false;
             sessionStorage.setItem('jobsMap', JSON.stringify(jobsMap));
            return;
          }
        if (job_status === 'failed') {
          setLoading(false);
          const now = new Date();
          const nowstr = now.toLocaleString();
          setAnalysis('At ' + nowstr + ' Tina says: \n\n' + data.errormsg);
          if (questionRef.current) {
            questionRef.current.focus();
           }
           jobsMap[index].resumesDirty = false;
           jobsMap[index].status = job_status;
           sessionStorage.setItem('jobsMap', JSON.stringify(jobsMap));
          return;
        }
        if (data.returncode === 401) {
          await renew_token(); 
          window.location.reload();
          }
      }
    }
  }
  };

  useEffect(() => {
    get_top_candidates();
  }, []);


  return (
    <div>
      <img src={tina1} alt="Tina your AI Talent Specialist" style={{ float: 'left', marginRight: '10px', verticalAlign: 'top' }} />
      <Typography variant="h4" align="center" paragraph>
        Candidate Analysis and Results
      </Typography>
      {loading && !interacting ? (
      <Typography variant="h6" paragraph>
        My results and recommendatons for the best candidates for the job description you provided will be shown below once I've completed the analysis. I am currently deeply analyzing each resume, and based on the number of candidates submitted, I am anticipating it will take me approximately <b>{jobsMap[index].estimated_mins} minutes</b> to complete my task from start to finish. I do appreciate your patience,  and trust me, it will be worth the wait!  Once complete, I'll be sending you an email with the finished results.  NOTE: Please check your spam folder if you don't see my email in your inbox, it will be coming from me: noreply@mg.hemingersolutions.com.  
      </Typography>
      ) : (
        <Typography variant="h6" paragraph>
        The most recent results of my search for the best candidates for the job description you provided are shown below.  I have deeply analyzed each of the candidate resumes you provided, and based on the information provided by the candidates, I have the following recommendations for those best suited to the job.  The areas where the candidates best align to the job requirements are indicated, as well as any gaps I found in their resumes. I hope you find the results helpful and have provided time savings in your recruiting process.  
        </Typography>
      )}
      {loading && !interacting ? (
      <Typography variant="h6" paragraph>
      While you're waiting for the results, feel free to use the back button and create or manage other job roles, or simply grab a cup of coffee and relax for a few minutes. I'll be working hard in the background to identify the best candidates possible.  Come back to this page at any time to check on status of my analysis, and I look forward to chatting with you about the results soon!  Unfortunately, I cannot intelligently speak about my recommendations until my analysis is complete, so I've disabled the chat feature until then.
      </Typography>
      ) : (
        <Typography variant="h6" paragraph>
        I'd love to speak with you regarding any feedback on my analysis or the candidate recommenendations.  You can ask me for recommendations to improve the job description, adjust any of the criteria in the job description, or direct me to emphasize one or more job requirements over the others. You can even ask me for other advice on the recruiting process. Please feel free to ask me anything, and I'll do my best to provide you with the best advice possible.
        </Typography>
      )}
      <Typography variant="h6" paragraph>
        <b>Job Title:</b> {jobsMap[index].rolename},   Number of Candidates: {jobsMap[index].numcandidates}
      </Typography>
      {loading ? (
      <div style={{ position: 'relative', minHeight: '417px' }}>   
        <img src="/gears.gif" alt="Loading..." style={{ 
          position: 'absolute', 
          top: 'calc(50% + 30px)',
          left: '50%', 
          transform: 'translate(-50%, -50%)',
          width: '300px', 
          height: '300px' // NOTE:  minHeight in the style above is set such that the question entery box does not move when the loading gif is displayed
       }} />
      </div>
    ) : (
        <TextField
          variant="outlined"
          multiline
          fullWidth
          placeholder="Candidate analysis will be displayed here"
          value={analysis}
          inputRef={analysisRef}
          minRows={20}
          maxRows={20}
          InputProps={{
            readOnly: true,
          }}
        />
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
      <form onSubmit={handleSubmit} style={{ display: 'flex', width: '100%' }}>
        <TextField
          variant="outlined"
          multiline
          minRows={5}
          maxRows={5}
          fullWidth
          style={{ width: '100%' }}
          placeholder="Ask your questions to Tina here"
          value={question}
          onChange={event => setQuestion(event.target.value)}
          disabled={loading}
          inputRef={questionRef}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: '10px' }}
          type="submit"
          disabled={loading}
        >
          Submit
        </Button>
      </form>
      </div>
      <div style={{ marginTop: '20px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleBack}
          //disabled={loading}
        >
          Back to Manage Jobs
        </Button>
        {showClearButton && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClear}
            disabled={loading}
            style={{ marginLeft: '10px' }}
          >
            Clear Question
          </Button>
        )}
      </div>
  </div>
    );
};

export default Results;