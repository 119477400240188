import React, { useState } from 'react';
import { Button, Typography, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import tina from '../static/tina.png';

function HomePage() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage1, setErrorMessage1] = useState('');
  const [errorMessage2, setErrorMessage2] = useState('');
  const baseurl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
  const authenticate_url = baseurl + 'authenticate';

  const handleLogin = async () => {
    event.preventDefault();
    sessionStorage.setItem('email', email);
    sessionStorage.setItem('password', password);
    try {
      const response = await fetch(authenticate_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email, password: password })
      });

      const data = await response.json();
    
      if (response.ok) {
        // If the login was successful, navigate to the next page
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('username', data.username);
        sessionStorage.setItem('userid', data.userid);
        history.push('/HandleJobs');
      } else {
        // If there was an error, display the error messages
        setErrorMessage1(data.errormsg);
      }
    }
    catch (error){
      console.error('Error with login, email: ' + email + ', password: ' + password + ', error: ' + error);
      setErrorMessage1('A network error occured, we could not reach the server.  Please try again later, or contact Heminger Solutions for assistance.');
      setErrorMessage2('Error Detail:' + error);
      
    }
  };


  return (
    <div>
      <Typography variant="h4" align="center" style={{ fontWeight: 'bold', marginBottom: '20px', marginTop: '20px' }}>
        Meet Tina, your AI Talent Acquisition Specialist
      </Typography>
      <img src={tina} alt="Tina" style={{ float: 'left', marginRight: '10px', verticalAlign: 'top' }} />
      <Typography variant="h6" paragraph>
        Hello, my name is Tina, and I am your personal AI Talent Acquisition specialist. My job is to make your job as easy, accurate and as fast as possible to find the absolute best talent for your company!
      </Typography>
      <Typography variant="h6" paragraph>
        I understand that as a recruiter or HR specialist in today's job market, it can be a totally overwhelming and time consuming process to find the handful of best-qualified candidates from the hundreds of submissions.  I have good news for you, I'm here to help!
      </Typography>
      <Typography variant="h6" paragraph>
        Using this website, you'll be able to quickly and easily upload up to 200 candidate resumes at a time, and based on a job description that you provide, I will find the top 3-5 candidates for you based on the experience, skillsets, education, and any other factors you require.  <strong>It's important to note that I am not performing simple keyword searches, but rather I am using a sophisticated AI algorithm to find the best candidates for you!</strong>
      </Typography>
      <Typography variant="h6" paragraph>
        Not only will I be able to find your top candidates, I'll also outline why each candidate is a good fit, as well as let you know about any gaps in their background which you may wish to investigate further. You'll be able to interact with me as much as you like to ask questions about the candidates, and adjust your job criteria as needed to find the best fit for your company.
      </Typography>
      <Typography variant="h6" paragraph style={{ marginBottom: '50px' }}>
        Please login below to get started.  If you do not have an account, please contact Heminger Solutions, Inc. below to get setup.  You're only a few simple steps away from quickly finding the best talent for your company!
      </Typography>
      <form
        onSubmit={handleLogin}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginBottom: '50px' }}
>
        <TextField
          autoFocus
          label="Email Address"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ width: '50%', marginBottom: '10px' }}
       />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ width: '50%', marginBottom: '10px' }}
        />
        <Button variant="contained" color="primary" type="submit">
          Login
        </Button>
        <Typography variant="body1" color="error" style={{ visibility: errorMessage1 ? 'visible' : 'hidden' ,marginTop: '30px'}}>
          {errorMessage1}
        </Typography>
        <Typography variant="body1" color="error" style={{ visibility: errorMessage2 ? 'visible' : 'hidden', marginBottom: '30px'}}>
          {errorMessage2}
        </Typography>
      </form>
    </div>
  );
}

export default HomePage;