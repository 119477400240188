import React, { useState, useEffect } from 'react';
import { Button, Typography, Table, TableRow, TableCell, TextField, Select, MenuItem, InputLabel, FormControl, TableBody } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import step1Image from '../static/step-1.png'; 
import step2Image from '../static/step-2.png'; 
import step3Image from '../static/step-3.png'; 


function HandleJobs() {
  const history = useHistory();
  const [selectedJob, setSelectedJob] = useState('');
  const [selectedJobDescription, setJobDescription] = useState('');
  const [isJobDescriptionAltered, setIsJobDescriptionAltered] = useState(false);
  const [isNewRole, SetNewRole] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedNumCandidates, setSelectedNumCandidates] = useState(0);
  const [numResumes, setNumResumes] = React.useState(0);
  let index = 0;
  let jobsMap = [];

  const baseurl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
  const getroles_url = baseurl + 'getroles';
  const addrole_url = baseurl + 'addrole';
  const removerole_url = baseurl + 'removerole';
  const addcandidates_url = baseurl + 'addcandidatestorole'
  const getresumefiles_url = baseurl + 'getcandidateresumes';
  const clearcandidates_url = baseurl + 'clearcandidates';
  const authenticate_url = baseurl + 'authenticate';

  useEffect(() => {
    const t = sessionStorage.getItem('token')
    const fetchData = async () => {
      const response = await fetch(getroles_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: t, userid: sessionStorage.getItem('userid') })
      });
      const data = await response.json();
      if (response.ok) {
        jobsMap = data.roles.map((role, index) => ({
          rolename: role.rolename,
          roleid: role.roleid,
          roledesc: role.roledesc,
          numcandidates: role.numcandidates.toString(),
          estimated_mins: (parseInt(role.estimated_processing_secs)/60.0).toString(),
          resumesDirty: false
        }));
        sessionStorage.setItem('jobsMap', JSON.stringify(jobsMap));
        sessionStorage.setItem('currentIndex', '0');
        setSelectedJob('0');
        setIsJobDescriptionAltered(false);
        if (jobsMap.length > 0) {
          setJobDescription(jobsMap[0].roledesc);
          updateResumes(0);
        } 
      }
        else {
          if (data.returncode === 401) {
            await renew_token();            
            window.location.reload();
            console.log('Token renewed, page reloaded');
            return;
          }
         console.error('Error fetching roles:', data.errormsg);
      }
    };
    fetchData();
  }, []);
  
  const renew_token = async () => {
    const email = sessionStorage.getItem('email');
    const password = sessionStorage.getItem('password');
    let response;
    let data;
    try {
      response = await fetch(authenticate_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email, password: password })
      });
      data = await response.json();
    } catch (error){
      console.error('A network error occured, attempting to refresh the session token.  Please login again, or contact Heminger Solutions for assistance.');
    }
    if (response.ok) {
      sessionStorage.setItem('token', data.token);
      console.log('Token renewed');
    }
  };

  const updateResumes = async (i) => {
    // Get list of candidate resumes from the server, and populate in our resume list box
    const jobsMap = JSON.parse(sessionStorage.getItem('jobsMap'));
    const t = sessionStorage.getItem('token')
    let response;
    let data;
    try {
      response = await fetch(getresumefiles_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: t, roleid: jobsMap[i].roleid})
      });
      data = await response.json();
      if (response.ok) {
        setUploadedFiles(data.resume_list);
        setSelectedNumCandidates(jobsMap[i].numcandidates);
        setNumResumes(jobsMap[i].numcandidates);  
      }
      else {
        if (data.returncode === 401) {
          await renew_token();            
          window.location.reload();
          console.log('Token renewed, page reloaded');
          return;
        }
        console.log('Error, no resumes returned, Error: ', data.errormsg);
      }
    } 
    catch (error) {
      console.log('EXCEPTION: Error fetching resume list, Error: ', error.message);
    } 
  };

  const handleJobChange = async (event) => {
    const index = parseInt(event.target.value, 10);
    const jobsMap = JSON.parse(sessionStorage.getItem('jobsMap'));
    setSelectedJob(index.toString());
    const newdesc = jobsMap[index].roledesc;
    setJobDescription(newdesc);
    sessionStorage.setItem('currentIndex', index.toString());
    setIsJobDescriptionAltered(false);
    updateResumes(index);
  };

  const handleDescriptionChange = (event) => {
    const newDescription = event.target.value;
    setJobDescription(newDescription);
    const index = parseInt(sessionStorage.getItem('currentIndex'), 10);
    const jobsMap = JSON.parse(sessionStorage.getItem('jobsMap'));
    setIsJobDescriptionAltered(newDescription !== jobsMap[index].roledesc);
    SetNewRole(jobsMap[index].roleid === '-1' ? true : false)
  };

  const handleSaveJobClick = async () => {
    const index = parseInt(sessionStorage.getItem('currentIndex'), 10);
    const jobsMap = JSON.parse(sessionStorage.getItem('jobsMap'));
    const t = sessionStorage.getItem('token')
    jobsMap[index].roledesc = selectedJobDescription;
    const body = { token: t, userid: sessionStorage.getItem('userid'), rolename: jobsMap[index].rolename, roledesc: selectedJobDescription };
    let response;
    let data;
    try {
      response = await fetch(addrole_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      data = await response.json();
      if (response.ok) {
        jobsMap[index].roleid = data.roleid;
        jobsMap[index].numcandidates = '0';
        jobsMap[index].resumesDirty = true;
        sessionStorage.setItem('jobsMap', JSON.stringify(jobsMap));
        setIsJobDescriptionAltered(false);
        SetNewRole(false);
        // Job Added successfully Message
      } else {
        if (data.returncode === 401) {
          await renew_token();            
          window.location.reload();
          console.log('Token renewed, page reloaded');
          return;
        }
        // Job Added Error Message
      }
    }
    catch (error){
      console.log('EXCEPTION: Save Job button failed. Error: ' + error.message);
      //setErrorMessage1('A network error occured, we could not reach the server.  Please try again later, or contact Heminger Solutions for assistance.');
    }
    console.log('Save Job button clicked');
  };

  const handleDeleteJobClick = async () => {
    const confirm = prompt('Are you sure?  Type "yes" to confirm.');
    const t = sessionStorage.getItem('token');
    let response;
    let data;
    if (confirm.toLowerCase() !== 'yes') {
      return;
    }
    const index = parseInt(sessionStorage.getItem('currentIndex'), 10);
    const jobsMap = JSON.parse(sessionStorage.getItem('jobsMap'));
    try {
      response = await fetch(removerole_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: t, userid: sessionStorage.getItem('userid'), roleid: jobsMap[index].roleid })
      });
      data = await response.json();
      if (response.ok) {
        jobsMap.splice(index, 1); // Remove the current job from jobsMap
        sessionStorage.setItem('jobsMap', JSON.stringify(jobsMap)); // Update jobsMap in the session storage
        sessionStorage.setItem('currentIndex', '0'); // Reset the current index to 0
        setSelectedJob('0');
        if (jobsMap.length > 0) {
          setJobDescription(jobsMap[0].roledesc);
          setNumResumes(jobsMap[0].numcandidates); // Update the number of resumes in the current job (0 if no resumes uploaded yet
          updateResumes(0);
        }
        else {  
          setJobDescription(''); // Clear the jobDescription text field
          setSelectedNumCandidates(0); // Clear the number of resumes uploaded
          setUploadedFiles([]); // Clear the resumes list
        }

        // Job Added Message
      } else {
        if (data.returncode === 401) {
          await renew_token();            
          window.location.reload();
          console.log('Token renewed, page reloaded');
          return;
        }
        // Job Added Error Message
      }
    }
    catch (error){
      console.log('Delete Job button failed, error:' + error.message);
      //setErrorMessage1('A network error occured, we could not reach the server.  Please try again later, or contact Heminger Solutions for assistance.');
    }
    console.log('Delete Job button clicked');
  };

  const handleAddJobClick = () => {
    const newJob = prompt('Enter the new job title:');
    const jobsMap = JSON.parse(sessionStorage.getItem('jobsMap'));
    const newJobMap = {
      rolename: newJob,
      roleid: '-1', // -1 indicates a new role that has not been saved to the database yet
      roledesc: '',
      numcandidates : 0,
      resumesDirty: false
    };
    jobsMap.push(newJobMap);
    sessionStorage.setItem('jobsMap', JSON.stringify(jobsMap));
    sessionStorage.setItem('currentIndex', (jobsMap.length - 1).toString());
    setSelectedJob(jobsMap.length -1);
    setJobDescription('');
    setNumResumes(0); 
    console.log('Add Job button clicked');
  };

  const MyDropzone = () => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: async (acceptedFiles) => {
        setUploadedFiles([...uploadedFiles, ...acceptedFiles.map(file => file.name)]);
        const formData = new FormData();
        acceptedFiles.forEach((file) => {
          formData.append('file', file);
          const reader = new FileReader();
          reader.onload = () => {
            // Do whatever you want with the file contents
          };
          reader.readAsArrayBuffer(file);
        });
        const userid = sessionStorage.getItem('userid');
        const token = sessionStorage.getItem('token');
        const index = parseInt(sessionStorage.getItem('currentIndex'), 10);
        let response;
        let data;
        if (index >= 0) {
          const roleid = jobsMap[index].roleid;
          const buildurl = addcandidates_url + '?token=' + token + '&userid=' + userid + '&roleid=' + roleid;
          response = await fetch(buildurl, {
            method: 'POST',
            body: formData
          });
          data = await response.json();
          if (response.ok) {
            jobsMap[index].numcandidates = data.numcandidates;
            jobsMap[index].resumesDirty = true;
            jobsMap[index].estimated_mins = data.estimated_processing_secs/60.0;
            sessionStorage.setItem('jobsMap', JSON.stringify(jobsMap));
            setSelectedNumCandidates(data.numcandidates);
            setNumResumes(data.numcandidates);  
          }
          else
          {
            if (data.returncode === 401) {
              await renew_token();            
              window.location.reload();
              console.log('Token renewed, page reloaded');
              return;
            }
            console.log('EXCEPTION: Error uploading files, Error: ', data.errormsg);
          } 
        }
      },
      multiple: true
    });

    return (
      <div {...getRootProps()} style={{ width: '75%', height: '170px', border: '1px solid black', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginRight: '10px' }}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p style={{ backgroundColor: 'lightgrey', margin: '0 30px', textAlign: 'center', fontWeight: 'bold' }}>Drop the files here ...</p> :
            <p style={{ backgroundColor: 'lightgrey', margin: '0 30px', textAlign: 'center', fontWeight: 'bold' }}>Drag 'n' drop one more more candidate resume files here, or click to select files</p>
        }
      </div>
    );
  };

  const handleShowTopCandidatesClick = () => {
    // Call the API...
    // Navigate to the Results page
    history.push('/Results');
  };

  const handleClearCandidates = async () => {
    const index = parseInt(sessionStorage.getItem('currentIndex'), 10);
    const jobsMap = JSON.parse(sessionStorage.getItem('jobsMap'));
    let response;
    let data;
    try {
      response = await fetch(clearcandidates_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: sessionStorage.getItem('token'), roleid: jobsMap[index].roleid })
      });
      data = await response.json();
      if (response.ok) {
        jobsMap[index].numcandidates = '0';
        jobsMap[index].resumesDirty = true; 
        sessionStorage.setItem('jobsMap', JSON.stringify(jobsMap)); // Update jobsMap in the session storage
        updateResumes(index);
        console.log('Clear Candidates succeeded')
      } else {
        if (data.returncode === 401) {
          await renew_token();            
          window.location.reload();
          console.log('Token renewed, page reloaded');
          return;
        }
        console.log('Clear Candidates failed, error: ' + data.errormsg);
      }
    }
    catch (error){
      console.log('Clear Candidates failed, error:' + error.message);
      //setErrorMessage1('A network error occured, we could not reach the server.  Please try again later, or contact Heminger Solutions for assistance.');
    }
    console.log('Clear button clicked');
  };

  jobsMap = JSON.parse(sessionStorage.getItem('jobsMap')) || [];
  index = parseInt(sessionStorage.getItem('currentIndex'), 10);

  return (
    <div>
      <Typography variant="h4" align="center" style={{ fontWeight: 'bold', marginBottom: '20px', marginTop: '40px' }}>
        You're just 3 short steps away from finding the best candidates!
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell align="left" style={{ width: '50px', verticalAlign: 'top' }}>
              <img src={step1Image} alt="Step 1" style={{ marginRight: '20px' }} />
            </TableCell>
            <TableCell align="left">
              <Typography variant="body1" align="left">
                {'Use the New Job button to create a new job title, or select an existing job title from the list.'}.<br></br>
              </Typography>
              <FormControl style={{ minWidth: '450px', marginTop: '20px' }}>
                <InputLabel id="job-select-label">Available Job Titles</InputLabel>
                <Select labelId="job-select-label" value={selectedJob} onChange={handleJobChange}>
                  {jobsMap.map((jobsMap, index) => (
                    <MenuItem key={index} value={index}>{jobsMap.rolename}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button variant="contained" color="primary" onClick={handleAddJobClick} style={{ marginLeft: '40px', marginTop: '30px'  }}>
              New Job
            </Button>
            </TableCell>
          </TableRow>
          <TableRow>
          <TableCell align="left" style={{ width: '50px', verticalAlign: 'top' }}>
              <img src={step2Image} alt="Step 2" style={{ marginRight: '20px' }} />
            </TableCell>
            <TableCell align="left">
            <Typography variant="body1" align="left">
                Paste in your job description in the box below or update an existing one if you wish.  Click on the Save/Update Job button to save your changes (if any), and move to step #3.<br></br>
              </Typography>
            <TextField
                multiline
                minRows={20}
                maxRows={20}
                variant="outlined"
                placeholder="Paste job description here"
                value={selectedJobDescription}
                onChange={handleDescriptionChange}
                style={{ marginTop: '20px', width: '100%', marginBottom: '20px' }}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={selectedJobDescription === '' || !isJobDescriptionAltered}
                style={{ backgroundColor: selectedJobDescription !== '' ? 'primary' : '', marginLeft: '10px' }}
                onClick={handleSaveJobClick}
              >
                {isJobDescriptionAltered & !isNewRole ? 'Update Job' : 'Save Job'}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={selectedJobDescription === ''}
                style={{ backgroundColor: selectedJobDescription !== '' ? 'red' : '', marginLeft: '10px' }}
                onClick={handleDeleteJobClick}
              >
                Delete Selected Job
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
              <TableCell align="left" style={{ width: '50px', verticalAlign: 'top' }}>
                <img src={step3Image} alt="Step 3" style={{ marginRight: '20px' }} />
              </TableCell>
              <TableCell align="left">
              <Typography variant="body1" align="left">
                  Upload up to 200 candidate resumes as applicants for the above job using drag and drop, or click the grey box to browse. We support .DOCX, .PDF, .RTF, and .TXT file formats. When you're done uploading resumes, click SHOW ME TOP CANDIDATES to see the best matches for your job!
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <div style={{ width: '85%' }}>
                  <MyDropzone />
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '10px', backgroundColor: 'green' }}
                    onClick={handleShowTopCandidatesClick}
                    disabled={numResumes < 1}
                  >
                    SHOW ME THE TOP CANDIDATES!
                  </Button>
                </div>
                <div style={{ width: '60%' }}>
                  <div style={{ width: '100%', height: '170px', overflow: 'auto', marginTop: '20px', border: '1px solid black', align:'left', marginBottom:'10px' }}>
                  {uploadedFiles.map((file, i) => (
                    <p key={i} style={{ textAlign: 'left', lineHeight: '0.8' }}>{file}</p>
                  ))}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="body1" align="left">
                        Number of resumes uploaded: {selectedNumCandidates}
                      </Typography>
                      <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClearCandidates}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

export default HandleJobs;