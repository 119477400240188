import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import HandleJobs from './components/HandleJobs';
import Results from './components/Results';
import './components/App.css'; 


function App() {
  return (
    <Router>
      <div className="app-container">
        <Header />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/HandleJobs" component={HandleJobs} />
          <Route path="/Results" component={Results} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;