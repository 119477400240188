import React from 'react';
import { AppBar, Toolbar, Typography, Link } from '@material-ui/core';

function Footer() {
  return (
    <footer className="footer"> {
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="body1" style={{ marginRight: '10px' }}>
            © 2024 Heminger Solutions, Inc.
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="body1" style={{ marginRight: '10px' }}>
              <Link href="/TermsOfService.pdf" target="_blank">
                Terms of Service
              </Link>
            </Typography>
            <Typography variant="body1" style={{ marginRight: '10px' }}>
              <Link href="/PrivacyPolicy.pdf" target="_blank">
                Privacy Policy
              </Link>
            </Typography>
          </div>
          <Typography variant="body1">
            <Link href="mailto:larry@hemingersolutions.com">
              larry@hemingersolutions.com
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
    } 
    </footer>
  );
}

export default Footer;